import { Markdoc } from "./Markdoc";
import { SideNav } from "./SideNav";
import { TocEntry, TableOfContents } from "./TableOfContents";

export type Sections = {
    title: string;
    links: {
        to: string;
        children: string;
    }[];
}[];

export function Page(props: { content: string, sections: Sections, toc: TocEntry[] }) {
    return (
        <>
            <div className="page">
                <SideNav sections={props.sections} />
                <main className="flex column">
                    <div id="skip-nav" />
                    <article>
                        <Markdoc content={props.content} />
                    </article>
                </main>
                <TableOfContents toc={props.toc} />
            </div>

            <style jsx global>
                {`
                    .page {
                        display: flex;
                        flex-grow: 1;
                        padding-top: var(--nav-height);
                        min-height: 100vh;
                        max-width: 100vw;
                    }

                 
                    .dark .page {
                        border-bottom-color: var(--black-light);
                    }

                    main {
                        flex-grow: 1;
                        max-width: 100%;
                        /* https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container */
                        min-width: 0;
                    }

                    main article {
                        padding: 2rem 1.5rem 3rem;
                    }


                 `}
            </style>

        </>);

}