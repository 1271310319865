"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdGenerator = void 0;
class IdGenerator {
    constructor() {
        this.generated = new Set();
    }
    generate(name) {
        let idBase = name
            .replace(/\//g, '-')
            .replace(/[?()]/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
        let id = idBase;
        let i = 1;
        while (this.generated.has(id)) {
            id = idBase + `-${i++}`;
        }
        this.generated.add(id);
        return id;
    }
}
exports.IdGenerator = IdGenerator;
