
import Markdoc, { Tag, Node, RenderableTreeNode } from '@markdoc/markdoc';
import React from 'react';
import { TocEntry } from './TableOfContents';
import { IdGenerator } from 'common';
import { useStore } from '../state';
import { useSearchParams } from 'react-router-dom';

export function MarkdocLoader(props: {index: string}) {

  let [searchParams] = useSearchParams();
  
  const setContent = useStore((state) => state.setPageContent);
  const setToc = useStore((state) => state.setToc);
  let src = "/docs/" + (searchParams.get("p") ?? "introduction") + ".md"

  React.useEffect(() => {
    let mounted = true;
    (async () => {
      setContent('loading...');
      const response = await fetch(src,
        {
          headers: {
            Accept: 'application/text'
          }
        }
      );

      if (!mounted) {
        return ;
      }
      
      if (response.status === 404) {
        setToc([]);
        setContent('404');
        return;
      }

      const rawText = await response.text();

      if (!mounted) {
        return ;
      }

      const {content, headings} = renderMarkdown(rawText);
      setContent(content);
      setToc(headings )
    })();

    return () => {mounted = false;};
  }, [src, setContent, setToc]);

  return <></>;
}

function renderMarkdown(rawText: string) {
  const ast = Markdoc.parse(rawText);

  let idGenerator = new IdGenerator();

  const config = {
    tags: {
      "anchor": {
        render: 'a',
        attributes: {
          id: { type: String, required: true },
        },
      }
    },
    nodes: {
      "fence": {
        render: 'CodeBlock',
        attributes: {
          language: { type: String },
          name: { type: String, required: false }
        },
      },
      "heading": {
        render: 'Heading',
        attributes: {
          id: { type: String },
          level: { type: Number, required: true, default: 1 },
          className: { type: String }
        },
        transform(node: Node, config: any) {
          const attributes = node.transformAttributes(config);
          const children = node.transformChildren(config);

          if (attributes.id && typeof attributes.id === 'string') {
            return new Tag(this.render, attributes, children);
          } else {
            const name = children.filter((child) => typeof child === 'string').join(' ')
            const id = idGenerator.generate(name)
            return new Tag(this.render, { ...attributes, id }, children);
          }
        },
      }
    },
  };

  const transformed = Markdoc.transform(ast, config);
  const content = JSON.parse(JSON.stringify(transformed))
  const headings = collectHeadings(transformed)
  return {content, headings};
}

function collectHeadings(node: RenderableTreeNode, sections: TocEntry[] = []) {
  if (Tag.isTag(node)) {
      if (node.name === "Heading") {
          const title = node.children[0];
          if (typeof title === 'string' && node.attributes['id'] != null && node.attributes["level"] != null) {
              sections.push({
                  id: node.attributes['id'],
                  level: node.attributes["level"],
                  title
              });
          }
      }

      if (node.children) {
          for (const child of node.children) {
              collectHeadings(child, sections);
          }
      }
  }
  return sections;
}
