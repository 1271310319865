
import { SearchResult, findMatches } from 'common';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from './Icon';
import { useStore } from '../state';


export type SearchProps = {}

export function SearchButton(props: SearchProps) {
    const setIsOpen = useStore((state) => state.setSearchboxVisible);

    const togglePopup = () => {
        setIsOpen(true);
    };

    return (
        <div className="search-button" aria-live="polite" onClick={togglePopup}>
            <Icon icon="search" />
            <div className="label">Search</div>
            <style jsx>
                {`
                    .search-button {
                        color: inherit;
                        background: var(--button-color);
                        display: flex;
                        align-items: center;
                        padding: 4px 8px;
                        border-radius: 16px;
                        margin-right: 16px;
                        cursor: default;
                    }
                    .search-button:hover {
                        background: var(--button-hover-color);
                    }
                    .label {
                        padding: 4px;
                        padding-left: 16px;
                    }
                `}
            </style>
        </div>
    );
}



export const SearchBox = () => {
    const isOpen = useStore((state) => state.isSearchboxVisible)
    const setIsOpen = useStore((state) => state.setSearchboxVisible)
    const searchIndex = useStore((state) => state.searchIndex)
    const setSearchIndex = useStore((state) => state.setSearchIndex)

    const closePopup = () => {
        setIsOpen(false);
    };

    const [searchText, setInputValue] = useState('');
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const onTextChange = (value: string) => {
        setInputValue(value);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setSearchResults(findMatches(searchIndex, searchText, 5));
    }, [searchText, searchIndex])

    React.useEffect(() => {
        let mounted = true;
        (async () => {
            const response = await fetch("/searchindex.json",
                {
                    headers: {
                        Accept: 'application/json'
                    }
                }
            );

            if (!mounted) {
                return;
            }

            if (response.status / 100 !== 2) {
                return;
            }

            setSearchIndex(await response.json());
        })();

        return () => {
            mounted = false;
        }
    }, [setSearchIndex])


    useEffect(() => {
        if (!inputRef.current) {
            return 
        }
        if (isOpen) {
            inputRef.current.focus();
        }
    }, [isOpen]);


    if (!isOpen) {
        return <></>
    }

    return (
        <div className="searchbox-overlay" onClick={closePopup}>
            <EscapeListener closePopup={closePopup}/>
            <div className="searchbox" onClick={(e) => e.stopPropagation()}>
                <div className="searchbox-header">
                    <input ref={inputRef} className="search-query" type="text"
                        value={searchText}
                        onChange={(e) => onTextChange(e.target.value)}
                        placeholder="Type something..." />
                </div>
                <div className="searchresults">
                    {searchResults.map(item => SearchResultComponent(item, closePopup))}
                </div>
            </div>

            <style jsx>
                {`
                    .searchbox-overlay {
                        background-color: var(--translucent);
                        height: 100vh;
                        left: 0;
                        position: fixed;
                        top: 0;
                        width: 100vw;
                        z-index: 200;
                    }

                    .searchbox {
                        position: relative;
                        margin: 60px auto auto;
                        width: var(--searchbox-modal-width);
                        padding: 20px;
                        background: var(--gray-light);
                        border-radius: 4px;
                        box-shadow: 0 2px 10px var(--translucent);
                    }

                    .search-query {
                        flex-grow: 1;
                        padding: 8px;
                    }

                    .searchbox-header {
                        display: flex;
                    }

                    .searchresults {
                        display: flex;
                        flex-direction: column;
                    }

            `}
            </style>
        </div>
    );
};



function SearchResultComponent(sr: SearchResult, closePopup: () => void) {
    return (
        <div className="searchresult">
            <a href={sr.href} onClick={closePopup}>{sr.text}</a>
            <style jsx>
                {`
                    .searchresult {
                        background: var(--light);
                        padding:8px;
                        margin: 4px 0;
                        border-radius: 2px;
                        box-shadow: 0 0 2px var(--translucent);
                    }
                `}
            </style>
        </div>
    );
}


function EscapeListener(props: { closePopup: () => void}) {
    // Function to handle the ESC key press
    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                props.closePopup();
            }
        };
        
      document.addEventListener('keydown', handleEsc);
      return () => {
        document.removeEventListener('keydown', handleEsc);
      };
    }, [props]);
  
    return <></>
  };