import * as React from "react"
const SvgComponent = (props: {}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={200}
        height={40}
        viewBox="0 20 200 40"
        {...props}
    >
        <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
                <path d="M516.689 1107.44h20.47V927.633h-20.47v179.807z" />
            </clipPath>
            <clipPath id="b" clipPathUnits="userSpaceOnUse">
                <path d="M569.294 1057.96h115.807V927.633H569.294v130.327z" />
            </clipPath>
            <clipPath id="c" clipPathUnits="userSpaceOnUse">
                <path d="M710.761 1057.7H819.36V925.56H710.761v132.14z" />
            </clipPath>
            <clipPath id="d" clipPathUnits="userSpaceOnUse">
                <path d="M837.204 1094.23h87.311V925.817h-87.311v168.413z" />
            </clipPath>
            <clipPath id="e" clipPathUnits="userSpaceOnUse">
                <path d="M942.663 1057.18h130.067V925.56H942.663v131.62z" />
            </clipPath>
            <clipPath id="f" clipPathUnits="userSpaceOnUse">
                <path d="M1107.19 1057.96h115.82V927.633h-115.82v130.327z" />
            </clipPath>
            <clipPath id="g" clipPathUnits="userSpaceOnUse">
                <path d="M1240.38 1094.23h87.31V925.817h-87.31v168.413z" />
            </clipPath>
        </defs>
        <g
            style={{
                fill: "#fff",
                fillOpacity: 1,
            }}
        >
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#a)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <path
                        d="M520.32 927.633h12.693v128.509H520.32V927.633zm16.839 169.705c0-13.472-20.47-13.472-20.47 0 0 13.473 20.47 13.473 20.47 0"
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                        }}
                    />
                </g>
            </g>
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#b)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <g
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                        }}
                    >
                        <path
                            d="M0 0v-22.8C9.842-5.174 27.724 2.073 45.6 2.337c34.2 0 57.771-20.992 57.771-56.485v-73.839H90.678v73.582c0 29.018-17.876 44.563-45.6 44.306C18.654-10.364.257-30.312.257-56.735v-71.252h-12.693V0H0Z"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }}
                            transform="translate(581.73 1055.62)"
                        />
                    </g>
                </g>
            </g>
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#c)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <g
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                        }}
                    >
                        <path
                            d="M0 0c-12.951 11.4-25.644 13.473-39.896 13.473-19.948.257-39.125-7.255-38.603-23.579.515-17.097 22.8-20.47 38.86-23.315 22.8-3.888 54.148-7.776 52.854-37.566-.779-28.246-30.054-35.758-52.597-35.758-22.535 0-44.82 8.548-55.963 25.652l9.335 8.291c10.62-15.289 29.789-21.764 46.893-21.764C-23.572-94.566 0-90.42.779-70.215c.522 18.397-20.727 22.028-41.711 25.394-24.873 4.146-49.488 8.806-49.746 34.457-.257 25.13 24.873 36.016 50.782 35.751 18.654 0 34.979-5.174 47.672-17.096L0 0Z"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }}
                            transform="translate(806.106 1032.305)"
                        />
                    </g>
                </g>
            </g>
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#d)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <g
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                        }}
                    >
                        <path
                            d="M0 0v-38.61h43.527v-10.621H0v-77.984c0-17.361 3.623-29.54 23.579-29.54 6.218 0 13.208 2.073 19.691 5.189l4.403-10.363c-8.034-3.889-16.06-6.483-24.094-6.483-27.202 0-36.015 16.067-36.015 41.197v77.984h-27.203v10.621h27.203v37.309L0 0Z"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }}
                            transform="translate(876.843 1094.23)"
                        />
                    </g>
                </g>
            </g>
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#e)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <g
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                        }}
                    >
                        <path
                            d="M0 0c0-32.127 23.322-53.633 52.34-53.633 71.252 0 71.252 107.002 0 107.002C23.322 53.369 0 32.127 0 0m117.373 63.99V-63.997h-12.701v27.202C92.236-56.743 73.067-66.07 52.34-66.07 16.582-66.07-12.693-39.124-12.693 0c0 39.117 29.275 65.548 65.033 65.548 20.727 0 41.712-9.585 52.332-29.533V63.99h12.701z"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }}
                            transform="translate(955.356 991.63)"
                        />
                    </g>
                </g>
            </g>
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#f)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <g
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                        }}
                    >
                        <path
                            d="M0 0v-22.8C9.842-5.174 27.717 2.073 45.6 2.337c34.2 0 57.779-20.992 57.779-56.485v-73.839H90.678v73.582c0 29.018-17.876 44.563-45.6 44.306C18.654-10.364.257-30.312.257-56.735v-71.252h-12.693V0H0Z"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }}
                            transform="translate(1119.63 1055.62)"
                        />
                    </g>
                </g>
            </g>
            <g
                style={{
                    fill: "#fff",
                    fillOpacity: 1,
                }}
            >
                <g
                    clipPath="url(#g)"
                    style={{
                        opacity: 0.800003,
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                    transform="matrix(.11212 0 0 -.11212 -12.105 154.827)"
                >
                    <g
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                        }}
                    >
                        <path
                            d="M0 0v-38.61h43.527v-10.621H0v-77.984c0-17.361 3.631-29.54 23.579-29.54 6.218 0 13.216 2.073 19.691 5.189l4.402-10.363c-8.033-3.889-16.067-6.483-24.093-6.483-27.21 0-36.015 16.067-36.015 41.197v77.984h-27.203v10.621h27.203v37.309L0 0Z"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }}
                            transform="translate(1280.017 1094.23)"
                        />
                    </g>
                </g>
            </g>
        </g>
        <g
            style={{
                fill: "#15a4ef",
                fillOpacity: 0.941176,
            }}
        >
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0c-18.14-18.133-42.234-27.203-66.07-27.203-61.145 0-93.015 42.748-93.272 91.722-.522 48.444 31.091 93.787 93.272 93.787 23.836 0 47.93-9.07 66.07-27.203l-9.592-8.291C-25.137 138.358-45.6 145.87-66.07 145.87c-52.854 0-80.064-37.309-79.8-80.837.515-42.49 27.46-79.277 79.8-79.277 20.47 0 41.197 7.769 56.735 23.057L0 0Z"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 156.627 48.003)"
                />
            </g>
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="m0 0-69.436-106.223h-4.668L-144.054 0v-161.15h-13.995V20.213h16.582l69.436-105.709L-3.366 20.213H13.73V-161.15H0V0Z"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 177.747 32.752)"
                />
            </g>
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0h64.254c31.605 0 45.6 17.875 45.857 43.27.258 21.241-14.252 43.27-44.306 43.27H0V0Zm130.581-81.872h-18.397L49.488-13.215H0v-68.657h-13.995V99.49h79.8c39.382 0 59.33-28.238 59.595-56.477.257-29.54-19.177-55.449-58.816-55.449l63.997-69.436z"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 185.36 41.64)"
                />
            </g>
        </g>
        <g
            style={{
                fill: "#15a4ef",
                fillOpacity: 0.941176,
            }}
        >
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0s-50.754-83.921-50.252-83.921c.503 0 0-80.403 0-80.403h29.146c5.528 42.714 33.669 44.724 33.669 44.724h54.775V0H0Z"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 5.635 33.55)"
                />
            </g>
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0c0-4.422-3.618-8.04-8.04-8.04h-219.099c-4.422 0-8.04 3.618-8.04 8.04v146.736c0 4.422 3.618 8.04 8.04 8.04H-8.04c4.422 0 8.04-3.618 8.04-8.04V0Z"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 40.962 46.114)"
                />
            </g>
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0c0-3.317-2.714-6.03-6.03-6.03h-129.148c-3.316 0-6.03 2.713-6.03 6.03v12.563c0 3.317 2.714 6.03 6.03 6.03H-6.03c3.316 0 6.03-2.713 6.03-6.03V0Z"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 30.426 50.565)"
                />
            </g>
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0c-5.921 0-10.72 4.8-10.72 10.72 0 5.921 4.799 10.721 10.72 10.721s10.72-4.8 10.72-10.721C10.72 4.8 5.921 0 0 0m0 44.557c-18.687 0-33.836-15.149-33.836-33.837 0-18.687 15.149-33.836 33.836-33.836 18.687 0 33.836 15.149 33.836 33.836 0 18.688-15.149 33.837-33.836 33.837"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 9.025 53.965)"
                />
            </g>
            <g
                style={{
                    fill: "#15a4ef",
                    fillOpacity: 0.941176,
                }}
            >
                <path
                    d="M0 0c-5.921 0-10.72 4.8-10.72 10.72 0 5.921 4.799 10.721 10.72 10.721s10.72-4.8 10.72-10.721C10.72 4.8 5.921 0 0 0m0 44.557c-18.687 0-33.836-15.149-33.836-33.837 0-18.687 15.149-33.836 33.836-33.836 18.687 0 33.836 15.149 33.836 33.836 0 18.688-15.149 33.837-33.836 33.837"
                    style={{
                        fill: "#15a4ef",
                        fillOpacity: 0.941176,
                        fillRule: "nonzero",
                        stroke: "none",
                    }}
                    transform="matrix(.11212 0 0 -.11212 35.318 53.965)"
                />
            </g>
        </g>
    </svg>
)
export default SvgComponent
