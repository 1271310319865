import './App.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { TopNav } from './components/TopNav';
import { SearchBox } from './components/Search';
import { ScrollTo } from './components/ScrollTo';
import { SkipNav } from './components/SkipNav';
import { FooterBar } from './components/FooterBar';

import {title, description} from './sitemap'
import { MainContent } from './components/MainContent';

export default function MyApp(props: {}) {
  return (
    <div>
      <ScrollTo />
      <SearchBox />
      <div>
        <Helmet>
          <title>{title}</title>
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="icon" href="/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="referrer" content="strict-origin" />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
        </Helmet>
        <SkipNav />
       
        <TopNav>
          <Link to="https://github.com/instantcmr/sdk">SDK</Link>
        </TopNav>
        <MainContent />
        <FooterBar />
      </div>
    </div>
  );
}
