

export function FooterBar(props: {}) {
    return <>
        <div className="footer-bar">
        </div>
        <style jsx global>
        {`

          .footer-bar {
            flex: 1;
            padding: 0;
            border-top: 1px solid var(--gray-medium)';
          }

          .footer-bar footer {
            margin: 0 auto;
            max-width: 'calc(100% - 4rem)';
          }

          @media screen and (max-width: 600px) {
            .footer-bar {
              padding: 2.5rem 0;
            }
          }
        `}
        </style>
    </>
}