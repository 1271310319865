
import { Route, Routes } from 'react-router-dom';
import { useStore } from '../state';
import { MarkdocLoader } from './MarkdocLoader';
import { Page } from './Page';

import {sections} from '../sitemap';

export function MainContent() {
    const toc = useStore((state) => state.toc);
    const content = useStore((state) => state.pageContent);
    const index = sections[0].links[0]
    return <>
        <Routes>
            <Route path="/">
                <Route path="" element={<MarkdocLoader index={index.to} />} />
            </Route>
        </Routes>
        <Page sections={sections} content={content} toc={toc} />
    </>;
}