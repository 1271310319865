import React from "react";

export type HeadingProps  = {
    id: string
    level: number,
    className?: string,
    children?: React.ReactNode
}

export const Heading: React.FC<HeadingProps> = (props: HeadingProps) => {
    const Component: any = `h${props.level}`;
    const link = (

      <Component className={['heading', props.className].filter(Boolean).join(' ')}>
        <div id={props.id} />
        {props.children}
        <style jsx>
          {`
            a {
              text-decoration: none;
            }
            a:hover {
              opacity: 1;
            }
            div {
              position: absolute;
              top: calc(-1 * (var(--nav-height) + 44px));
            }
          `}
        </style>
      </Component>
    );
  
    return props.level !== 1 ? (
      <a href={`#${props.id}`}>
        {link}
        <style jsx>
          {`
            a {
              text-decoration: none;
            }
            a:hover {
              opacity: 1;
            }
            a :global(.heading::after) {
              opacity: 0;
              color: var(--toc-border);
              content: '  #';
              transition: opacity 250ms ease;
            }
            a :global(.heading:hover::after) {
              opacity: 1;
            }
          `}
        </style>
      </a>
    ) : link;
  }
