import { create } from 'zustand'
import { SearchIndex } from 'common';
import { TocEntry } from './components/TableOfContents';

export type State = {
    isSearchboxVisible: boolean,
    setSearchboxVisible: (open: boolean) => void,
    searchIndex: SearchIndex,
    setSearchIndex: (searchIndex: SearchIndex) => void,

    pageContent: string,
    setPageContent: (st: string) => void,

    toc:  TocEntry[],
    setToc: (toc: TocEntry[]) => void
}

export const useStore = create<State>((set) => ({
    isSearchboxVisible: false,
    setSearchboxVisible: (open: boolean) => set((state) => ({ isSearchboxVisible: open })),
    searchIndex: [],
    setSearchIndex: (searchIndex: SearchIndex) => set((state) => ({ searchIndex })),

    pageContent: "",
    setPageContent: (content: string) => set((state) => ({ pageContent: content })),
    toc: [],
    setToc: (toc: TocEntry[]) =>  set((state) => ({ toc })),
}))
