
import React from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../state";


export function ScrollTo() {
    const { pathname, hash } = useLocation();
    const content = useStore(store => store.pageContent);
  
    // scroll to top when path changes
    React.useEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
      });
    }, [pathname]);
  
    // page is dynamically rendered, we need to check for content and hash changes and scroll down to the desired element
    React.useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView();
        }
      }
    }, [content, hash]);
  
    return null;
  }

  
  