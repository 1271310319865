

export function SkipNav(props: {}) {
    // https://webaim.org/techniques/skipnav/
    return <>
        <a href="#skip-nav" className="skip-nav">
            Skip to content
        </a>

        <style jsx global>
          {`

          .skip-nav {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            position: absolute;
            text-decoration: none;
          }

          .skip-nav:focus {
            padding: 1rem;
            position: fixed;
            top: 10px;
            left: 10px;
            background: var(--light);
            z-index: 1000;
            width: auto;
            height: auto;
            clip: auto;
          }
        `}
        </style>
    </>
}
