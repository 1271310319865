
import MarkdocLib from '@markdoc/markdoc';
import { CodeBlock } from './CodeBlock';
import React from 'react';
import { Heading } from './Heading';

export const Markdoc: React.FC<{ content: string }> = (props): any => {
    const components = {
      'CodeBlock': CodeBlock,
      'Heading': Heading,
    };
  
    return <div>
      {MarkdocLib.renderers.react(props.content, React, {
        components
      })}
    </div>
  }