"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findMatches = findMatches;
function findMatches(searchIndex, searchText, limit) {
    let searchResults = [];
    if (searchText.length > 1) {
        const searchWords = searchText.toLowerCase().match(/\w+/g) || [];
        for (let item of searchIndex) {
            let [kind, href, text] = item;
            let words = text.toLowerCase().match(/\w+/g) || [];
            let match = searchWords.every(searchWord => words.some(word => word.startsWith(searchWord)));
            if (match) {
                searchResults.push({
                    kind: kind,
                    href: href,
                    text: text,
                });
            }
            if (searchResults.length == limit) {
                break;
            }
        }
    }
    return searchResults;
}
