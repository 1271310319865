import React from "react";
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Search : React.FC = () => {return <></>}

export function TopNav(props: {children?:any}) {
    const [showMobileNav, setShowMobileNav] = React.useState(false);
    return (
      <div className="nav-bar">
        <nav>
          <div className="flex top-row">
            <Link to="/" className="flex">
            <Logo />
            </Link>
            <button
              className="hamburger"
              onClick={() => setShowMobileNav((o) => !o)}
            >
              <svg
                width="16"
                height="10"
                viewBox="0 0 16 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="2" fill="var(--black)" />
                <rect y="4" width="16" height="2" fill="var(--black)" />
                <rect y="8" width="16" height="2" fill="var(--black)" />
              </svg>
            </button>
          </div>
          <section className={showMobileNav ? 'active' : ''}>
            {props.children}
            <Search />
          </section>
        </nav>
        <style jsx={true}>
          {`
            .nav-bar {
              top: 0;
              position: fixed;
              z-index: 100;
              display: flex;
              width: 100%;
              background: var(--navbar-background);
              color: var(--light);
            }
            nav {
              display: flex;
              gap: 1rem;
              width: 100%;
              margin: 0 auto;
              align-items: center;
              justify-content: space-between;
              padding: 8px 32px;
              font-size: 15px;
              font-family: var(--sans);
            }
            nav :global(a) {
              text-decoration: none;
            }
            section {
              display: flex;
              align-items: center;
              gap: 1.3rem;
              padding: 0;
            }
            button {
              display: none;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 32px;
              background: var(--gray-light);
              border-radius: 30px;
            }
            .top-row {
              align-items: center;
              justify-content: space-between;
              width: 100%;
            }
            @media screen and (max-width: 600px) {
             
              nav {
                flex-direction: column;
                align-items: flex-start;
                border-bottom: none;
              }
              section {
                display: none;
                font-size: 15px;
              }
              section.active {
                display: flex;
              }
              button {
                display: flex;
              }
            }
          `}
        </style>
      </div>
    );
  }
  