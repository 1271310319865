
export const title = 'instantCMR API documentation';
export const description = 'instantCMR API documentation, your comprehensive resource for integrating and optimizing logistics operations using our platform.';


export const sections = [
    {
        title: 'Get started',
        links: [
            { to: '?p=/introduction', children: 'Introduction'},
            { to: '?p=/use-cases', children: 'Use cases'},
        ],
    },
    {
        title: 'Integration guidelines',
        links: [
            { to: '/?p=/first-steps', children: 'First steps' },
            { to: '/?p=/authentication', children: 'Authentication' },
            { to: '/?p=/entity-versioning', children: 'Entity Versioning' },
            { to: '/?p=/anti-spam', children: 'Anti spam' },
        ]
    },
    {
        title: 'Endpoints',
        links: [
            { to: '/?p=/trip-api', children: 'Trip API' },
            { to: '/?p=/user-api', children: 'User API' },
            { to: '/?p=/chat-api', children: 'Chat API' },
            { to: '/?p=/document-storage-api', children: 'Document storage API' },
            { to: '/?p=/transaction-api', children: 'Transaction API' },
            { to: '/?p=/custom-menu-api', children: 'Custom menu API' },
        ]
    },
    {
        title: 'Schemas',
        links: [
            { to: '/?p=/trip-schemas', children: 'Trip' },
            { to: '/?p=/user-schemas', children: 'User' },
            { to: '/?p=/chat-schemas', children: 'Chat' },
            { to: '/?p=/document-storage-schemas', children: 'Document storage' },
            { to: '/?p=/transaction-schemas', children: 'Transaction' },
            { to: '/?p=/custom-menu-schemas', children: 'Custom menu' },
        ]
    }
];
